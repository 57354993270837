/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('#{$fonts-directory}roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('#{$fonts-directory}roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fonts-directory}roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fonts-directory}roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('#{$fonts-directory}roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fonts-directory}roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('#{$fonts-directory}roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('#{$fonts-directory}roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fonts-directory}roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fonts-directory}roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$fonts-directory}roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fonts-directory}roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* blinker-regular - latin */
@font-face {
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fonts-directory}blinker-v1-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Blinker'), local('Blinker-Regular'),
       url('#{$fonts-directory}blinker-v1-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fonts-directory}blinker-v1-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fonts-directory}blinker-v1-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('#{$fonts-directory}blinker-v1-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fonts-directory}blinker-v1-latin-regular.svg#Blinker') format('svg'); /* Legacy iOS */
}

/* blinker-700 - latin */
@font-face {
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 700;
  src: url('#{$fonts-directory}blinker-v1-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Blinker Bold'), local('Blinker-Bold'),
       url('#{$fonts-directory}blinker-v1-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$fonts-directory}blinker-v1-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$fonts-directory}blinker-v1-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$fonts-directory}blinker-v1-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$fonts-directory}blinker-v1-latin-700.svg#Blinker') format('svg'); /* Legacy iOS */
}
