$actSelector: "html.header-overlay-active";
$inactSelector: "html.header-overlay-inactive";

@keyframes header-overlay-active {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes header-overlay-inactive {
    from {
        top: 0;
        opacity: 1;
    }

    99% {
        top: 0;
    }

    to {
        opacity: 0;
        top: -100%;
    }
}




#c268 {

    display: inline-block;
    position: absolute;
    right: 50%;
    transform: translateX(890px);
    top: 90px;
    z-index: 1000;


    ul {
        li{
            a {
                display: none;
            }
            
            ul {
                li {
                    display: inline-block;
                    margin-left: 40px;
                    text-transform: uppercase;
                }
            
                a {
                    color: black;
                    text-decoration: none;
                    display: initial;
                    font-weight: $font-weight-bold;
                    font-family: $font-family-blinker;
                
                    &:hover {
                        color: $color-highlight;
                    }
                }
            }
        }
    }
}



#{$actSelector} {
    #c268 {
        position: fixed;
        z-index: 2000;
        display: initial;
        left: 50%;
        transform: translateX(-923px);
        top: 10%;
        @include header-middle-font-and-size();
        text-transform: uppercase;

        @media screen and (max-width: 2000px) {
            left: 4rem;
            transform: initial;
        }

        @include mediaQuery($device_smaller_than_laptop) {
            font-size: 3rem;
            top: 6rem;

            ul {
                width: 80vw;
            }
        }

        li {
            margin-top: 30px;
        }

        a {
            color: #fff;
            text-decoration: none;

            &:hover {
                color: rgba(255,255,255,0.7);
            }
        }
    }
}




.openingtimes {
    display: block;
    background-color: black;

    .page-centered {
        position: relative;
        height: 40px;

        .times {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            color: white;
            padding: 0;
        }
    }
}


#header {
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    height: 170px;
    position: relative;

    &__social {
        width: 40px;
        position: absolute;
        right: 0;
        top: 45px;

        &__white {
            display: none;
        }
    }

    &__logo {
        width: 470px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &__toggle {
        display: none;
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(876px);
        width: 41px;
        z-index: 10000;

        @media screen and (max-width: 2000px){
            left: initial;
            transform: initial;
            right: 3.8rem;
        }

        #menu-sign {
            position: absolute;
            right: 30px;
            text-transform: uppercase;
            color: $color-highlight;
            right: 55px;
            top: 50%;
            transform: translateY(-50%);
            white-space: nowrap;
        }

        &__button {
            position: absolute;
            width: 41px;
            height: 41px;
            //background-color: rgba(red, 0.5);
            top: 50px;

            .bars {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 41px;
                height: 3px;
                background-color: black;
                transition: width 0.3s ease-in-out;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    width: 41px;
                    height: 3px;
                    background-color: black;
                    transition: all 0.3s ease-in-out;
                }

                &:before {
                    top: -7px;
                    left: 5px;
                }

                &:after {
                    top: 7px;
                    left: 5px
                }
            }

            // state of button when overlay is active - transforms to a X
            #{$actSelector} & {

                #menu-sign {
                    color: white;
                }

                .whitediv {
                    width: 100%;
                    height: 200px;
                    background-color:red;
                }

                .bars {
                    width: 0;

                    &:before,
                    &:after {
                        left: -20px;
                        top: 0;
                        width: 35px;
                        background-color: #fff;
                    }

                    &:before {
                        transform: rotate(-45deg);
                    }

                    &:after {
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    &__overlay {
    

        z-index: 101;
        position: fixed;
        top: -100%;
        opacity: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: 60vw;

        .overlaycontainer {
            height: 100%;
        }


        .links {
            a {
               text-decoration: none; 
            }
            
        }



        #{$actSelector} & {
            animation-duration: 0.8s;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-name: header-overlay-active;
            animation-fill-mode: forwards;
            top: 0;
        }

        #{$inactSelector} & {
            animation-duration: 0.8s;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-name: header-overlay-inactive;
            animation-fill-mode: forwards;
        }
    }
}

@media screen and (max-width: 2060px) {

    .openingtimes {
        padding: 0 2rem;
    }

    #c268 {
        right: 7.5rem;
        transform: initial;
    }

    #header {

        &__logo {
            left: 2rem;
        }

        &__social {
            right: 2rem;
        }
    }
}

@media screen and (max-width: 1500px) {
    #{$actSelector} {
        #c268 {
            li {
                margin-top: 20px;
                font-size: 50px;
            }
        }
    }
}

@include mediaQuery($device_smaller_than_desktop) {

    #header {

        &__logo {
            width: 300px;
        }

        &__toggle {
            margin-left: 32px;
        }
    }
}

@include mediaQuery($device_laptop) {
    #{$actSelector} {
        #c268 {
            left: 2rem;
        }
    }

    #header {

        &__logo {
            left: 2rem;
        }

        &__toggle {
            top: -30px;
            right: 1.8rem;
        }

        &__overlay {
            background-size: 60vw;
        }
    }
}

@media screen and (max-width: 1000px) {
    #c268 {
        display: none;
    }

    #header {

        height: 110px;

        &__logo {
            left: 2rem;
        }

        &__toggle {
            display: initial;
            top: 30px;
            right: 1.8rem;

            &__button {
                top: 67px;
                transform: translate(-50%, -50%);
            }
        }

        &__overlay {

            background-size: 80vw;

            &__logo {
                top: 23px;
            }
        }

        &__social {
            display: none;
        }
    }


    #{$actSelector} {
        #header {

            &__social__white {
                width: 40px;
                position: fixed;
                display: initial;
                top: 80vh;
                left: 2rem;
                right: initial;
                z-index: 200;
            }
        }
    }

    #{$actSelector} {
        #c268 {
            display: initial;
            left: 2rem;
            transform: initial;

            li {
                font-size: 80%;
                display: block;
                margin-left: 0;
            }
        }
    }
}


@include mediaQuery($device_smartphone) {

    #header {
        &__logo {
            left: 1rem;
            width: 200px;
        }

        &__toggle {
            right: 1rem;
        }

        &__overlay {

            background-size: 180vw;

            &__logo {
                top: 23px;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .openingtimes {
        padding: 0 1rem;

        .times {
            font-size: 3.7vw;
        }
    }
}

@media screen and (max-width: 355px) {
    #{$actSelector} {
        #c268 {

            li {
                font-size: 70%;
            }
        }
    }
}
