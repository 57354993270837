@import "mixins";

// Social Media Colors
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
$color-xing: #026466;
$color-twitter: #00aced;
$color-facebook: #3b5998;
$color-googleplus: #dd4b39;
$color-pinterest: #cb2027;
$color-linkedin: #007bb6;
$color-youtube: #bb0000;
$color-vimeo: #1ab7ea;
$color-tumblr: #32506d;
$color-instagram: #bc2a8d;
$color-flickr: #ff0084;
$color-dribbble: #ea4c89;
$color-quora: #a82400;
$color-foursquare: #0072b1;
$color-forrst: #5b9a68;
$color-vk: #45668e;
$color-wordpress: #21759b;
$color-stumbleupon: #eb4823;
$color-yahoo: #7b0099;
$color-blogger: #fb8f3d;
$color-soundcloud: #ff3a00;

// Basic font size
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
$root-pixel:18;

// additional font sizes
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
$size--16: -1rem;
$size--12: -0.75rem;
$size--8: -0.5rem;
$size--4: -0.25rem;
$size-8: 0.5rem;
$size-16: 1rem;
$size-24: 1.5rem;
$size-32: 2rem;
$size-40: 2.5rem;
$size-48: 3rem;
$size-56: 3.5rem;
$size-64: 4rem;
$size-72: 4.5rem;
$size-80: 5rem;
$size-88: 5.5rem;
$size-96: 6rem;
$size-104: 6.5rem;
$size-112: 7rem;
$size-120: 7.5rem;
$size-128: 8rem;
$size-136: 8.5rem;
$size-144: 9rem;
$size-152: 9.5rem;
$size-160: 10rem;

// additional sizes
$size-text-1: 0.697875rem; // 11.166px small text
$size-text-2: 0.875rem; // 14px text
$size-text-3: 1.3125rem; // 24px Header
$size-text-4: 1.625rem; // 26px Header


// touch or non touch is the question
$touch-selector: "html.touchevents"; // it is a touch device
$no-touch-selector: "html.no-touchevents"; // it is not a touch device

// Some devices to use with media queries
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
// ... smartphone
$device_small_smartphone: "(max-width: 320px)";

$device_smartphone: "(max-width: 480px)";
$device_larger_than_smartphone: "(min-width: 481px)";

// ... tablet
$device_smaller_than_tablet: "(max-width: 480px)";
$device_tablet: "(min-width: 481px) and (max-width: 768px)";
$device_larger_than_tablet: "(min-width: 769px)";

// ... laptop
$device_smaller_than_laptop: "(max-width: 768px)";
$device_laptop: "(min-width: 769px) and (max-width: 1024px)";
$device_larger_than_laptop: "(min-width: 1025px)";

// ... standard (all not the other devices)
$device_smaller_than_standard: "(max-width: 1024px)";
$device_standard: "(min-width: 1025px) and (max-width: 1280px)";
$device_larger_than_standard: "(min-width: 1281px)";

// ... desktop and larger
$device_smaller_than_desktop: "(max-width: 1280px)";
$device_desktop: "(min-width: 1281px)";

// ... breakpoint for content max width
$content_max: 1790px;
$device_larger_than_content_max: "(min-width: 1791px)";
$device_smaller_than_content_max: "(max-width: 1790x)";

// Fonts
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

$font-family: unquote("'Roboto', sans-serif");
$font-family-blinker: unquote("'Blinker', sans-serif");

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

$letter-spacing: 0.1em;

// Font-Sizes
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

@mixin bodytext-font-and-size() {
    $min: 16;
    $max: 18;
    font-family: $font-family;
    font-weight: $font-weight-light;
    font-size: 100vw / 1280 * $max;
    line-height: 1.6;

    .size-smaller {
        font-size: 80%;
    }

    @include mediaQuery("(max-width: 1350px)") {
        font-size: 16px;
    }

    @include mediaQuery("(max-width: 1040px)") {
        font-size: $min + px;
    }

    @include mediaQuery($device_desktop) {
        font-size: $max + px;
    }
}

@mixin header-font-and-size() {
    $min: 50;
    $max: 120;
    line-height: 1.2;
    font-size: 100vw / 1280 * $max;
    font-family: $font-family-blinker;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing;

    @include mediaQuery("(max-width: 600px)") {
        font-size: $min + px;
    }

    @include mediaQuery($device_desktop) {
        font-size: $max + px;
    }
}
 
@mixin header-middle-font-and-size() {
    $min: 30;
    $max: 60;
    line-height: 1.2;
    font-size: 100vw / 1280 * $max;
    font-family: $font-family-blinker;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing;

    @include mediaQuery("(max-width: 1350px)") {
        font-size: 34px;
    }

    @include mediaQuery("(max-width: 830px)") {
        font-size: $min + px;
    }

    @include mediaQuery($device_desktop) {
        font-size: $max + px;
    }
}

@mixin header-small-font-and-size() {
    $min: 20;
    $max: 34;
    line-height: 1.2;
    font-size: 100vw / 1280 * $max;
    font-family: $font-family-blinker;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing;

    @include mediaQuery("(max-width: 1350px)") {
        font-size: 24px;
    }

    @include mediaQuery("(max-width: 780px)") {
        font-size: $min + px;
    }

    @include mediaQuery($device_desktop) {
        font-size: $max + px;
    }
}

@mixin header-extrasmall-font-and-size() {
    $min: 22;
    $max: 24;
    line-height: 1.2;
    font-size: 100vw / 1280 * $max;
    font-family: $font-family-blinker;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing;

    @include mediaQuery("(max-width: 1350px)") {
        font-size: 20px;
    }

    @include mediaQuery("(max-width: 780px)") {
        font-size: $min + px;
    }

    @include mediaQuery($device_desktop) {
        font-size: $max + px;
    }
}

@mixin header-table-font-and-size() {
    $min: 16;
    $max: 22;
    line-height: 1.2;
    font-size: 100vw / 1280 * $max;
    font-family: $font-family-blinker;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing;

    @include mediaQuery("(max-width: 780px)") {
        font-size: $min + px;
    }

    @include mediaQuery($device_desktop) {
        font-size: $max + px;
    }
}

@mixin subheader-font-and-size() {
    $min: 16;
    $max: 26;
    line-height: 1.2;
    font-size: 100vw / 1280 * $max;
    font-family: 'Roboto', sans-serif;
    font-weight: $font-weight-regular;

    @include mediaQuery("(max-width: 780px)") {
        font-size: $min + px;
    }

    @include mediaQuery($device_desktop) {
        font-size: $max + px;
    }
}

// Standard paddings and margins for paragraphs, headlines, lists etc
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

@mixin standard-padding-bottom($multiplier: 1) {
    padding-bottom: 1rem * $multiplier;

    @include mediaQuery($device_desktop) {
        padding-bottom: 1rem * $multiplier;
    }

    @include mediaQuery($device_smartphone) {
        padding-bottom: 1rem;
    }
}

@mixin standard-padding-top($multiplier: 1) {
    padding-top: 1rem * $multiplier;

    @include mediaQuery($device_desktop) {
        padding-top: 1rem * $multiplier;
    }

    @include mediaQuery($device_smartphone) {
        padding-top: 1rem;
    }
}

@mixin standard-padding-top-bottom() {
    padding-top: 1.75vw;
    padding-bottom: 1.75vw;

    @include mediaQuery($device_desktop) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @include mediaQuery($device_smartphone) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

@mixin standard-padding() {
    padding: 3.5vw;

    @include mediaQuery($device_desktop) {
        padding: 2rem;
    }

    @include mediaQuery($device_smartphone) {
        padding: 0.5rem;
    }
}

// additional colors
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

$color-highlight: #A5000F;
$color-error: #FF0000;
$color-grey: #424242;
$color-grey-light: #EBEBEB;


$default-font-color: #000;
$default-font-hover-color: $color-highlight;
$default-link-color: #000;
$default-link-hover-color: $color-highlight;
$default-background-color: #fff;

// params each line: css-class color a:hover-color
$fontColors: ("color-white" white $color-highlight, "color-grey" $color-grey $color-highlight, "color-black" black $color-highlight, "color-highlight" $color-highlight black);

$backgroundColors: (
    "background-color-white" white,
    "background-color-grey" $color-grey,
    "background-color-black" black,
    "background-color-grey-light" $color-grey-light,
    "background-color-red" $color-highlight

);
