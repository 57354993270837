#footer {
    //.content {
    //    
    //}

    // Logo + Address + Contact
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    .sections {
        display: flex;
        justify-content: space-between;
        align-items: start;

        & > a,
        & > div {
            display: inline-block;
        }

        a {
            text-decoration: none;
        }
    }

    // Logo
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    .logo {
        //width: 250px;
        width: 25%;

        img {
            width: 60%;
        }

        svg {
            width: inherit;
            height: auto;
        }
    }

    // Contact
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    .address {
        padding-right: 1rem;
    }

    // Contact
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    .openingtimes-footer {
        margin-right: 5rem;

        @media only screen and (max-width: 1000px){
            margin-right: 0;
        }
        a {
            text-decoration: none;

            i {
                color: $color-highlight;
                width: 1.5em;
                text-align: center;
            }
        }
    }

    // Navigation
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    nav.info {
        ul,
        li {
            display: inline-block;
        }

        li {
            margin-left: 2em;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                display: none;
            }
        }

        a {
            text-decoration: none;
            font-size: 80%;

            &.active {
                color: $color-highlight;
            }
        }
    }
}




@include mediaQuery($device_tablet) {
    // alignment of section items on tablet
    #footer {
        .sections {
            flex-wrap: wrap;
            //margin-left: -2rem;
            align-items: flex-end;
        }

        a.logo {
            width: 51%;
            //padding-left: 2rem;
        }

        .address,
        .openingtimes-footer {
            width: 50%;
            //padding-left: 2rem;
            margin-top: 2rem;
        }
        .address {
            padding-right: 1rem;
        }
        .openingtimes-footer {
            padding-left: 1rem;
        }
    }
}

@include mediaQuery($device_smartphone) {
    // alignment of section items on smartphone
    #footer {
        .sections {
            display: block;
        }


        a.logo {
            display: block;
            width: 75%;

            img {
                width: 100%
            }
        }
        .address {
            padding-right: initial;
        }

        .address,
        .openingtimes-footer {
            display: block;
            width: 100%;
            margin-top: 2rem;
        }
    }
}