.openingtimes {
    nav.meta {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        bottom: initial;
        padding: 0;
        display: inline-block;
        right: 0;
        
        ul, li, a {
            display: inline-block;
        }
    
        li {
    
            &:first-child {
                margin-left: 0;
                margin-right: 20px;
    
                &:before {
                    display: none;
                }
            }
        
            a {
                text-decoration: none;
                color: #fff;
                font-size: 18px;
        
                #{$no-touch-selector} &:hover,
                &.active {
                    color: rgba(255,255,255,0.7);
                }
            }
        }
    }
}



.header-overlay-active nav.meta {
    position: absolute;
    bottom: 10%;
    padding-top: 3rem;
    padding-left: 4rem;

    @media screen and (max-width: 1000px) {
        padding-left: 2rem;
        top: 65vh;
    }

    ul, li, a {
        display: inline-block;
    }

    li {

        &:first-child {
            margin-left: 0;
            margin-right: 20px;

            &:before {
                display: none;
            }
        }
    }

    a {
        text-decoration: none;
        color: #fff;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: $font-weight-light;

        #{$no-touch-selector} &:hover,
        &.active {
            color: rgba(255,255,255,0.7);
        }
    }
}


@media screen and (max-width: 1000px) {
    .openingtimes {

        .openingtimes-header {
            display: none;
        }

        nav.meta {
            display: none;
        }
    }
}